.PostListPage_Container {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

form.PostListPage_Add_Post_Form {
  display: flex;
  justify-items: space-between;
  flex-direction: column;
  padding: 15px;
  align-items: center;
  max-width: 275px;
  border-radius: 8px;
  color: #000638;
  background-color: rgb(248, 244, 244);
  font-size: 17px;
  font-weight: bold;
  margin-top: 3em;
  margin-left: 15px;
  margin-right: 15px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  overflow: hidden;
}

textarea {
  margin: 10px;
  max-width: 490px;
  min-width: 200px;
  height: 70px;
  border-radius: 5px;
  border-style: groove;
  font-size: 17px;
  color: #000638;
}

textarea:hover {
  cursor: pointer;
}

form:hover {
  background: #17cfcf77;
}

@media screen and (min-width: 575px) {
  form.PostListPage_Add_Post_Form {
    max-width: 500px;
    min-width: 445px;
  }
}
