.CommentForm {
  display: flex;
  flex-direction: column;
  padding: 15px;
  align-items: center;
  justify-content: space-evenly;
  max-width: 500px;
  border-radius: 8px;
  color: #000638;
  font-size: 17px;
  font-weight: bold;
  margin-left: 20px;
  margin-right: 20px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  overflow: hidden;
  background-color: rgb(248, 244, 244);
}

textarea:hover {
  cursor: pointer;
}

.CommentForm__button {
  border-radius: 20px;
  border: 1px solid #17cfcf;
  background-color: #17cfcf;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

.CommentForm__button:hover {
  cursor: pointer;
  background-color: #000638;
}

.CommentForm__button:active {
  transform: scale(0.95);
}

.CommentForm__button:focus {
  outline: none;
}
