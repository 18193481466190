.LandingPage {
  max-width: 700px;
  min-width: 350px;
  margin: 30px;
  padding: 10px;
  background-color: rgb(248, 244, 244);
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  overflow: hidden;
}

header h1 {
  margin-top: 20px;
  padding: 8px;
}

header h3 {
  margin: 5px;
  padding: 2px;
}

section.LandingPage__why {
  text-align: left;
  margin: 15px;
  padding: 10px;
}

button:active {
  transform: scale(0.95);
}

.LandingPage a:link,
.LandingPage a:visited {
  color: white;
  text-align: center;
  text-decoration: none;
}
