.toolbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #17cfcf;
  height: 56px;
}

.toolbar__navigation {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 1rem;
}

.toolbar__logo {
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  padding-top: 1rem;
}

.toolbar__logo a img {
  color: white;
  text-decoration: none;
  max-width: 120px;
}

.spacer {
  flex: 1;
}

.spacer > input {
  width: 400px;
  float: left;
}

.spacer > .search-icon {
  float: left;
  padding: 8px;
  margin: 8px auto;
}

.spacer > .search-icon > svg {
  height: 20px;
  width: 20px;
}

.toolbar_navigation_items a {
  color: white;
  text-decoration: none;
}

.toolbar_navigation_items a:hover,
.toolbar_navigation_items a:active {
  color: #000638;
  font-weight: bold;
}

.toolbar_navigation_items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.toolbar_navigation_items li {
  padding: 0 0.5rem;
  list-style: none;
}

.disabled {
  pointer-events: none;
  color: grey;
}

.disabled:hover,
.disabled:active {
  color: grey;
}

@media (max-width: 908px) {
  .toolbar_navigation_items {
    display: none;
  }

  /* .spacer {
    flex: 1;
  } */

  .spacer > input,
  .spacer > .search-icon,
  .spacer > .search-icon > svg {
    display: none;
  }
}

@media (min-width: 909px) {
  .toolbar__toggle-button {
    display: none;
  }

  .toolbar__logo {
    margin-left: 0;
  }
}
