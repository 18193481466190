.CommentsItem__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 8px;
  color: #000638;
  padding: 15px;
  margin: 20px;
  max-width: 500px;
  min-width: 300px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  overflow: hidden;
  background-color: rgb(248, 244, 244);
}
