.side-drawer {
  height: 100%;
  background: #17cfcf;
  box-shadow: 2px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  max-width: 400px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.side-drawer.open {
  transform: translateX(0);
}

.side-drawer ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 15px;
  text-align: left;
}

.side-drawer li {
  margin: 0.5rem 0;
}
.side-drawer a {
  color: #ffffff;
  text-decoration: none;
  font-size: 1.2rem;
}

.side-drawer a:hover,
.side-drawer a:active {
  color: #000638;
  font-weight: bold;
}

.spacer__sideDrawer {
  flex: 1;
}

.spacer__sideDrawer > input {
  width: 150px;
  float: left;
}

.spacer__sideDrawer > .search-icon__sideDrawer {
  float: left;
  padding: 8px;
  margin: 8px auto;
}

.spacer__sideDrawer > .search-icon__sideDrawer > svg {
  height: 20px;
  width: 20px;
}

@media (min-width: 908px) {
  .side-drawer {
    display: none;
  }
}
