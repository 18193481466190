.PostPage__main {
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 8px;
  background-color: #17cfcf77;
  color: #000638;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 100px;
  margin-bottom: 30px;
  max-width: 500px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  overflow: hidden;
}
