.PostListItem__heading,
.PostListItem__date,
.PostListItem__footer {
  color: #000638;
}

.PostListItem__heading {
  text-align: left;
  padding: 5px;
  margin: 20px;
  font-size: 17px;
}

.PostListItem__header {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-color: #000638;
  border-radius: 8px;
  color: #000638;
  padding: 10px;
  margin: 20px;
  max-width: 275px;
  background-color: rgb(248, 244, 244);
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  overflow: hidden;
}

.PostListItem__header:hover {
  background-color: #17cfcf77;
}

.PostListItem__footer {
  align-items: center;
  padding: 5px;
}

ul {
  list-style: none;
}

@media screen and (min-width: 575px) {
  .PostListItem__header {
    max-width: 500px;
  }
}
