.message-body {
  display: grid;
  place-items: center;
}

.message-container {
  position: sticky;
  display: grid;
  grid:
    "search-container chat-title" 55px
    "conversation-list chat-message-list" 1fr
    "new-message-container chat-form" 55px
    / 200px 3fr;
  /* min-width: 200px; */
  max-width: 600px;
  max-height: 600px;
  min-height: 500px;
  width: 100%;
  /* height: 95vh; */
  background: #ddd;
  color: #17cfcf;
  border-radius: 10px;
  margin-top: 30px;
}

#search-container,
#conversation-list,
#new-message-container {
  background: #17cfcf;
  /* max-width: 150px; */
}

#search-container {
  display: grid;
  align-items: center;
  padding: 0 10px;
  grid-area: search-container;
  border-radius: 10px 0 0 0;
  box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.75);
  z-index: 1;
}

#search-container input {
  color: #fff;
  outline: none;
  font-weight: bold;
  border-radius: 2px;
  height: 30px;
  border: 0;
  padding: 10px;
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.3);
}

#search-container input::placeholder {
  color: #fff;
  font-weight: bold;
  text-align: left;
}

#conversation-list {
  grid-area: conversation-list;
  overflow-y: scroll;
}

#conversation-list > ul {
  margin: 0;
  padding: 0;
}

.conversation {
  /* display: grid;
  grid-template-columns: 40px 1fr max-content; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* grid-gap: 10px; */
  color: #fff;
  font-size: 1rem;
  border-bottom: 1px solid #339494;
  padding: 10px 10px 20px 15px;
}

.conversation.active {
  background: #000638;
}

.conversation:hover {
  cursor: pointer;
  background: #000638;
}

.conversation > svg {
  grid-row: span 2;
  height: 40px;
  width: 40px;
  border-radius: 100%;
}

.title-text {
  font-weight: bold;
  color: #eee;
  padding-left: 5px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.created-date {
  color: rgb(153, 149, 149);
  font-size: 0.5rem;
}

.conversation-message {
  grid-column: span 2;
  padding-left: 5px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

#new-message-container {
  display: grid;
  grid: 80px / 50px;
  align-content: center;
  grid-area: new-message-container;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 0 0 0 10px;
  padding: 0 15px;
}

#new-message-container a {
  display: grid;
  place-content: center center;
  background: #eee;
  border-radius: 100%;
  color: #339494;
  text-decoration: none;
  font-size: 3.6rem;
}

#chat-title,
#chat-form {
  background: #eee;
}

#chat-title {
  display: grid;
  grid: 36px / 1fr 40px;
  align-content: center;
  grid-area: chat-title;
  color: #17cfcf;
  font-weight: bold;
  border-radius: 0 10px 0 0;
  box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.75);
  padding: 0 20px;
}

#chat-title > .chat-title-username {
  font-size: 1.9rem;
}

#chat-title > svg {
  display: none;
}

#chat-message-list {
  grid-area: chat-message-list;
  display: flex;
  flex-direction: column-reverse;
  padding: 0 10px;
  overflow-y: scroll;
}

.message-row {
  display: grid;
  grid-template-columns: 70%;
  margin-bottom: 20px;
}

.message-content {
  display: grid;
}

.you-message {
  justify-content: end;
}

.you-message .message-content {
  justify-items: end;
}

.other-message {
  justify-content: start;
}

.other-message .message-content {
  grid-template-columns: 1fr 48px;
  /* grid-column-gap: 15px; */
  justify-items: start;
}

.message-row > svg {
  border-radius: 100%;
  grid-row: span 2;
}

.message-text {
  padding: 9px 14px;
  font-size: 1.6rem;
  margin-bottom: 5px;
}

.message-time {
  font-size: 1.3rem;
  color: #777;
}

.you-message .message-text {
  background: #17cfcf;
  color: #eee;
  border: 1px solid #17cfcf;
  border-radius: 14px 14px 0 14px;
}

.other-message .message-text {
  background: #eee;
  color: #111;
  border: 1px solid #ddd;
  border-radius: 14px 14px 14px 0;
}

#chat-form {
  display: grid;
  grid-template-columns: 9fr 0.5fr;
  align-content: center;
  align-items: center;
  grid-gap: 15px;
  width: 100%;
  grid-area: chat-form;
  border-radius: 0 0 10px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  padding-left: 70px;
  margin-left: 0px;
  padding-right: 22px;
}

#chat-form input {
  outline: none;
  padding: 5px;
  width: 150px;
  border: 2px solid #ddd;
  color: #330;
  border-radius: 6px;
  font-size: 1rem;
}

#chat-form > svg {
  font-size: 2rem;
}

::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 450px) {
  .message-container {
    position: sticky;
    display: grid;
    grid:
      "search-container chat-title" 45px
      "conversation-list chat-message-list" 1fr
      "new-message-container chat-form" 45px
      / 100px 1fr;
    width: 100%;
    max-width: 300px;
  }

  #search-container,
  #conversation-list,
  #new-message-container {
    max-width: 100px;
  }

  #search-container input {
    padding: 5px;
  }

  #search-container input::placeholder {
    color: #ddd;
    font-weight: bold;
    text-align: left;
  }

  .conversation {
    padding: 10px;
    font-size: 0.85rem;
  }
  /*   
  .conversation.active,
  .conversation:hover {
    background: #000638;
  } */

  #chat-title,
  #chat-form {
    width: 200px;
  }

  #chat-title {
    display: grid;
    grid-area: chat-title;
  }

  #chat-title > .chat-title-username {
    font-size: 1rem;
  }

  #chat-title > svg {
    display: none;
  }

  #chat-form {
    padding-left: 10px;
  }
}

@media screen and (min-width: 700px) {
  .message-container {
    position: sticky;
    display: grid;
    grid:
      "search-container chat-title" 71px
      "conversation-list chat-message-list" 1fr
      "new-message-container chat-form" 78px
      / 275px 1fr;
    min-width: 800px;
    max-width: 1000px;
    max-height: 800px;
    width: 100%;
    height: 95vh;
    background: #ddd;
    border-radius: 10px;
  }

  #search-container,
  #conversation-list,
  #new-message-container {
    background: #17cfcf;
  }

  #search-container {
    display: grid;
    align-items: center;
    padding: 0 20px;
    grid-area: search-container;
    border-radius: 10px 0 0 0;
    box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.75);
    z-index: 1;
  }

  #search-container input {
    color: #eee;
    outline: none;
    font-weight: bold;
    border-radius: 2px;
    height: 30px;
    border: 0;
    padding-left: 48px;
    padding-right: 20px;
    font-size: 1.4rem;
    background: rgba(255, 255, 255, 0.3);
  }

  #search-container input::placeholder {
    color: #ddd;
    font-weight: bold;
  }

  #conversation-list {
    grid-area: conversation-list;
    overflow-y: scroll;
  }

  #conversation-list > ul {
    margin: 0;
    padding: 0;
  }

  .conversation {
    display: grid;
    grid-template-columns: 40px 1fr max-content;
    grid-gap: 10px;
    color: #ddd;
    font-size: 1.3rem;
    border-bottom: 1px solid #339494;
    padding: 20px 20px 20px 15px;
  }

  /* .conversation.active,
  .conversation:hover {
    background: #000638;
  } */

  .conversation:hover {
    cursor: pointer;
  }

  .conversation > svg {
    grid-row: span 2;
    height: 40px;
    width: 40px;
    border-radius: 100%;
  }

  .title-text {
    font-weight: bold;
    color: #eee;
    padding-left: 5px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  .created-date {
    color: #ddd;
    font-size: 1rem;
  }

  .conversation-message {
    grid-column: span 2;
    padding-left: 5px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  #new-message-container {
    display: grid;
    grid: 80px / 50px;
    align-content: center;
    grid-area: new-message-container;
    border-top: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 0 0 0 10px;
    padding: 0 15px;
  }

  #new-message-container a {
    display: grid;
    place-content: center center;
    background: #eee;
    border-radius: 100%;
    color: #339494;
    text-decoration: none;
    font-size: 3.6rem;
  }

  #chat-title,
  #chat-form {
    background: #eee;
  }

  #chat-title {
    display: grid;
    grid: 36px / 1fr 36px;
    align-content: center;
    grid-area: chat-title;
    color: #17cfcf;
    font-weight: bold;
    border-radius: 0 10px 0 0;
    box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.75);
    padding: 0 20px;
  }

  #chat-title > .chat-title-username {
    font-size: 2rem;
  }

  #chat-title > svg {
    cursor: pointer;
    font-size: 2rem;
  }

  #chat-message-list {
    grid-area: chat-message-list;
    display: flex;
    flex-direction: column-reverse;
    padding: 0 20px;
    overflow-y: scroll;
  }

  .message-row {
    display: grid;
    grid-template-columns: 70%;
    margin-bottom: 20px;
  }

  .message-content {
    display: grid;
  }

  .you-message {
    justify-content: end;
  }

  .you-message .message-content {
    justify-items: end;
  }

  .other-message {
    justify-content: start;
  }

  .other-message .message-content {
    grid-template-columns: 1fr 48px;
    grid-column-gap: 15px;
    justify-items: start;
  }

  .message-row > svg {
    border-radius: 100%;
    grid-row: span 2;
  }

  .message-text {
    padding: 9px 14px;
    font-size: 1.6rem;
    margin-bottom: 5px;
  }

  .message-time {
    font-size: 1.3rem;
    color: #777;
  }

  .you-message .message-text {
    background: #17cfcf;
    color: #eee;
    border: 1px solid #17cfcf;
    border-radius: 14px 14px 0 14px;
  }

  .other-message .message-text {
    background: #eee;
    color: #111;
    border: 1px solid #ddd;
    border-radius: 14px 14px 14px 0;
  }

  #chat-form {
    display: grid;
    grid-template-columns: 9fr 1fr;
    align-content: center;
    align-items: center;
    grid-gap: 15px;
    grid-area: chat-form;
    border-radius: 0 0 10px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.25);
    padding-left: 42px;
    padding-right: 22px;
  }

  #chat-form input {
    outline: none;
    padding: 15px;
    border: 2px solid #ddd;
    color: #330;
    width: 100%;
    border-radius: 6px;
    font-size: 1.4rem;
  }

  #chat-form > svg {
    font-size: 2rem;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}
