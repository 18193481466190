.UsersListItem {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  overflow: hidden;
  color: #000638;
  width: 768px;
  max-width: 250px;
  min-height: 200px;
  padding: 20px;
}

.UsersListItem:link {
  color: #000638;
}

button {
  margin: 10px;
}

@media screen and (min-width: 768px) {
  .UsersListItem {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    overflow: hidden;
    width: 768px;
    max-width: 100%;
    min-height: 200px;
  }
}
