@import url(https://fonts.googleapis.com/css?family=Montserrat:400,800);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,800);
body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f6f5f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.toolbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #17cfcf;
  height: 56px;
}

.toolbar__navigation {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 1rem;
}

.toolbar__logo {
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  padding-top: 1rem;
}

.toolbar__logo a img {
  color: white;
  text-decoration: none;
  max-width: 120px;
}

.spacer {
  flex: 1 1;
}

.spacer > input {
  width: 400px;
  float: left;
}

.spacer > .search-icon {
  float: left;
  padding: 8px;
  margin: 8px auto;
}

.spacer > .search-icon > svg {
  height: 20px;
  width: 20px;
}

.toolbar_navigation_items a {
  color: white;
  text-decoration: none;
}

.toolbar_navigation_items a:hover,
.toolbar_navigation_items a:active {
  color: #000638;
  font-weight: bold;
}

.toolbar_navigation_items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.toolbar_navigation_items li {
  padding: 0 0.5rem;
  list-style: none;
}

.disabled {
  pointer-events: none;
  color: grey;
}

.disabled:hover,
.disabled:active {
  color: grey;
}

@media (max-width: 908px) {
  .toolbar_navigation_items {
    display: none;
  }

  /* .spacer {
    flex: 1;
  } */

  .spacer > input,
  .spacer > .search-icon,
  .spacer > .search-icon > svg {
    display: none;
  }
}

@media (min-width: 909px) {
  .toolbar__toggle-button {
    display: none;
  }

  .toolbar__logo {
    margin-left: 0;
  }
}

.toggle-button {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
  width: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
}

.toggle-button:focus {
  outline: none;
}

.toggle-button__line {
  width: 30px;
  height: 2px;
  background: white;
}

button.toggle-button:hover {
  background: transparent;
}

.side-drawer {
  height: 100%;
  background: #17cfcf;
  box-shadow: 2px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  max-width: 400px;
  z-index: 200;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

.side-drawer.open {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.side-drawer ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 15px;
  text-align: left;
}

.side-drawer li {
  margin: 0.5rem 0;
}
.side-drawer a {
  color: #ffffff;
  text-decoration: none;
  font-size: 1.2rem;
}

.side-drawer a:hover,
.side-drawer a:active {
  color: #000638;
  font-weight: bold;
}

.spacer__sideDrawer {
  flex: 1 1;
}

.spacer__sideDrawer > input {
  width: 150px;
  float: left;
}

.spacer__sideDrawer > .search-icon__sideDrawer {
  float: left;
  padding: 8px;
  margin: 8px auto;
}

.spacer__sideDrawer > .search-icon__sideDrawer > svg {
  height: 20px;
  width: 20px;
}

@media (min-width: 908px) {
  .side-drawer {
    display: none;
  }
}

.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  margin: 50px;
}

.Hyph {
  margin-right: 5px;
  margin-left: 5px;
}

.Input,
.Textarea {
  border: 1px solid #ddd;
  display: block;
  font-size: 1.1em;
  margin-bottom: 10px;
  padding: 10px;
  text-align: left;
  width: 100%;
  border-radius: 4px;
}

.Input::-webkit-input-placeholder, .Textarea::-webkit-input-placeholder {
  color: #ccc;
}

.Input:-ms-input-placeholder, .Textarea:-ms-input-placeholder {
  color: #ccc;
}

.Input::-ms-input-placeholder, .Textarea::-ms-input-placeholder {
  color: #ccc;
}

.Input::placeholder,
.Textarea::placeholder {
  color: #ccc;
}

.Textarea {
  resize: vertical;
}

.Button {
  order: 1px solid #aaa;
  background-color: #eee;
  font-size: 1.1em;
  border-radius: 4px;
  padding: 8px 12px;
  font-weight: bold;
}

.Required {
  color: #ca1551;
  font-family: Arial;
  font-weight: bold;
}

.Section {
  margin-left: 20px;
  margin-right: 20px;
}
.Section--list {
  margin-left: 10px;
  margin-right: 10px;
}

.CommentForm {
  display: flex;
  flex-direction: column;
  padding: 15px;
  align-items: center;
  justify-content: space-evenly;
  max-width: 500px;
  border-radius: 8px;
  color: #000638;
  font-size: 17px;
  font-weight: bold;
  margin-left: 20px;
  margin-right: 20px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  overflow: hidden;
  background-color: rgb(248, 244, 244);
}

textarea:hover {
  cursor: pointer;
}

.CommentForm__button {
  border-radius: 20px;
  border: 1px solid #17cfcf;
  background-color: #17cfcf;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  -webkit-transition: -webkit-transform 80ms ease-in;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
}

.CommentForm__button:hover {
  cursor: pointer;
  background-color: #000638;
}

.CommentForm__button:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

.CommentForm__button:focus {
  outline: none;
}

.PostPage__main {
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 8px;
  background-color: #17cfcf77;
  color: #000638;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 100px;
  margin-bottom: 30px;
  max-width: 500px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  overflow: hidden;
}

.CommentsItem__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 8px;
  color: #000638;
  padding: 15px;
  margin: 20px;
  max-width: 500px;
  min-width: 300px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  overflow: hidden;
  background-color: rgb(248, 244, 244);
}

* {
  box-sizing: border-box;
}

main.signUp__signIn {
  background: #f6f5f7;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  height: 120vh;
  margin: -20px 0 50px;
}

.demoUser {
  border-style: solid;
  border-radius: 50%;
  border-width: 2px;
  border-color: #dddddd;
  color: rgb(143, 143, 143);
  margin-left: -175px;
  margin-top: -30px;
  width: 125px;
  height: 125px;
}
.demoUser p {
  line-height: 15px;
}

form.signUp__signIn-form:hover {
  background: #ffffff;
}

form.sign-in-container-form:hover {
  background: #ffffff;
}

h1 {
  font-weight: bold;
  margin: 0;
}

h2 {
  text-align: center;
}

.overlay-container p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}

span {
  font-size: 12px;
}

a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}

button {
  border-radius: 20px;
  border: 1px solid #17cfcf;
  background-color: #17cfcf;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  -webkit-transition: -webkit-transform 80ms ease-in;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
}

button:hover {
  cursor: pointer;
  background-color: #000638;
}

button:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

button:focus {
  outline: none;
}

button.ghost {
  background-color: transparent;
  border-color: #ffffff;
}

button.ghost:hover {
  background-color: #000638;
}

form.signUp__signIn-form,
form.sign-in-container-form {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 20px;
  height: 100%;
  text-align: center;
}

.signUp__signIn-form {
  padding-bottom: 15px;
}

input {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}

.container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: scroll;
  min-width: 350px;
  max-width: 650px;
  min-height: 690px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.form-container {
  position: absolute;
  top: 0;
  height: 50%;
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}

.sign-in-container {
  top: 0;
  width: 100%;
  z-index: 2;
}

.container.right-panel-active .sign-in-container {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}

.sign-up-container {
  top: 10;
  width: 100%;
  opacity: 0;
  z-index: 1;
}

.container.right-panel-active .sign-up-container {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  opacity: 1;
  z-index: 5;
  -webkit-animation: show 0.6s;
          animation: show 0.6s;
}

@-webkit-keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-container {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 50%;
  overflow: scroll;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
  z-index: 100;
}

.container.right-panel-active .overlay-container {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}

.overlay {
  background: #17cfcf;
  background: -webkit-gradient(linear, left top, right top, from(#17cfcf), to(#25c0c0));
  background: linear-gradient(to right, #17cfcf, #25c0c0);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

.overlay-left {
  -webkit-transform: translateY(-20%);
          transform: translateY(-20%);
}

.container.right-panel-active .overlay-left {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.overlay-right {
  right: 0;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.container.right-panel-active .overlay-right {
  -webkit-transform: translateY(20%);
          transform: translateY(20%);
}
/* 
@media screen and (max-width: 400px) {
  main.signUp__signIn {
    margin: 20px 0 50px;
  }
} */

@media only screen and (min-width: 768px) {
  /* main.signUp__signIn {
    margin: -20px 0px 50px;
  } */

  .demoUser {
    border-style: solid;
    border-radius: 50%;
    border-width: 2px;
    border-color: #dddddd;
    color: rgb(143, 143, 143);
    margin-left: -175px;
    margin-top: -148px;
    width: 125px;
    height: 125px;
    overflow: hidden;
  }
  .demoUser p {
    line-height: 16px;
  }

  .container {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    position: relative;
    overflow: scroll;
    width: 768px;
    max-width: 100%;
    min-height: 480px;
  }

  .form-container {
    position: absolute;
    top: 0;
    padding-bottom: none;
    padding-top: 30px;
    height: 100%;
    -webkit-transition: all 0.6s ease-in-out;
    transition: all 0.6s ease-in-out;
  }

  .sign-in-container {
    left: 0;
    width: 50%;
    z-index: 2;
  }

  .container.right-panel-active .sign-in-container {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }

  .sign-up-container {
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
  }

  .container.right-panel-active .sign-up-container {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    -webkit-animation: show 0.6s;
            animation: show 0.6s;
  }

  @-webkit-keyframes show {
    0%,
    49.99% {
      opacity: 0;
      z-index: 1;
    }

    50%,
    100% {
      opacity: 1;
      z-index: 5;
    }
  }

  @keyframes show {
    0%,
    49.99% {
      opacity: 0;
      z-index: 1;
    }

    50%,
    100% {
      opacity: 1;
      z-index: 5;
    }
  }

  .overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: scroll;
    -webkit-transition: -webkit-transform 0.6s ease-in-out;
    transition: -webkit-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
    z-index: 100;
  }

  .container.right-panel-active .overlay-container {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }

  .overlay {
    background: #17cfcf;
    background: -webkit-gradient(linear, left top, right top, from(#17cfcf), to(#25c0c0));
    background: linear-gradient(to right, #17cfcf, #25c0c0);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: #ffffff;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-transition: -webkit-transform 0.6s ease-in-out;
    transition: -webkit-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
  }

  .container.right-panel-active .overlay {
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
  }

  .overlay-panel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    top: 0;
    height: 100%;
    width: 50%;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-transition: -webkit-transform 0.6s ease-in-out;
    transition: -webkit-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
  }

  .overlay-left {
    -webkit-transform: translateX(-20%);
            transform: translateX(-20%);
  }

  .container.right-panel-active .overlay-left {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  .overlay-right {
    right: 0;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  .container.right-panel-active .overlay-right {
    -webkit-transform: translateX(20%);
            transform: translateX(20%);
  }
}

/* 
.social-container {
  margin: 20px 0;
} */

/* .social-container a {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
} */

/* footer {
  background-color: #222;
  color: #fff;
  font-size: 14px;
  bottom: 0;
  position: fixed;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 999;
}

footer p {
  margin: 10px 0;
}

footer i {
  color: red;
}

footer a {
  color: #3c97bf;
  text-decoration: none;
} */

.PostListItem__heading,
.PostListItem__date,
.PostListItem__footer {
  color: #000638;
}

.PostListItem__heading {
  text-align: left;
  padding: 5px;
  margin: 20px;
  font-size: 17px;
}

.PostListItem__header {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-color: #000638;
  border-radius: 8px;
  color: #000638;
  padding: 10px;
  margin: 20px;
  max-width: 275px;
  background-color: rgb(248, 244, 244);
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  overflow: hidden;
}

.PostListItem__header:hover {
  background-color: #17cfcf77;
}

.PostListItem__footer {
  align-items: center;
  padding: 5px;
}

ul {
  list-style: none;
}

@media screen and (min-width: 575px) {
  .PostListItem__header {
    max-width: 500px;
  }
}

.PostListPage_Container {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

form.PostListPage_Add_Post_Form {
  display: flex;
  justify-items: space-between;
  flex-direction: column;
  padding: 15px;
  align-items: center;
  max-width: 275px;
  border-radius: 8px;
  color: #000638;
  background-color: rgb(248, 244, 244);
  font-size: 17px;
  font-weight: bold;
  margin-top: 3em;
  margin-left: 15px;
  margin-right: 15px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  overflow: hidden;
}

textarea {
  margin: 10px;
  max-width: 490px;
  min-width: 200px;
  height: 70px;
  border-radius: 5px;
  border-style: groove;
  font-size: 17px;
  color: #000638;
}

textarea:hover {
  cursor: pointer;
}

form:hover {
  background: #17cfcf77;
}

@media screen and (min-width: 575px) {
  form.PostListPage_Add_Post_Form {
    max-width: 500px;
    min-width: 445px;
  }
}

.LandingPage {
  max-width: 700px;
  min-width: 350px;
  margin: 30px;
  padding: 10px;
  background-color: rgb(248, 244, 244);
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  overflow: hidden;
}

header h1 {
  margin-top: 20px;
  padding: 8px;
}

header h3 {
  margin: 5px;
  padding: 2px;
}

section.LandingPage__why {
  text-align: left;
  margin: 15px;
  padding: 10px;
}

button:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

.LandingPage a:link,
.LandingPage a:visited {
  color: white;
  text-align: center;
  text-decoration: none;
}

.UsersListItem {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  overflow: hidden;
  color: #000638;
  width: 768px;
  max-width: 250px;
  min-height: 200px;
  padding: 20px;
}

.UsersListItem:link {
  color: #000638;
}

button {
  margin: 10px;
}

@media screen and (min-width: 768px) {
  .UsersListItem {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    overflow: hidden;
    width: 768px;
    max-width: 100%;
    min-height: 200px;
  }
}

.UsersListPage_container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.UserProfile__container {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  overflow: hidden;
  width: 768px;
  max-width: 275px;
  min-height: 480px;
  padding: 20px;
  color: #000638;
}

button {
  margin: 10px;
}

@media screen and (min-width: 550px) {
  .UserProfile__container {
    max-width: 375px;
  }
}

@media screen and (min-width: 800px) {
  .UserProfile__container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    position: relative;
    overflow: hidden;
    width: 768px;
    max-width: 100%;
    min-height: 480px;
  }
}

.message-body {
  display: grid;
  place-items: center;
}

.message-container {
  position: -webkit-sticky;
  position: sticky;
  display: grid;
  grid:
    "search-container chat-title" 55px
    "conversation-list chat-message-list" 1fr
    "new-message-container chat-form" 55px
    / 200px 3fr;
  /* min-width: 200px; */
  max-width: 600px;
  max-height: 600px;
  min-height: 500px;
  width: 100%;
  /* height: 95vh; */
  background: #ddd;
  color: #17cfcf;
  border-radius: 10px;
  margin-top: 30px;
}

#search-container,
#conversation-list,
#new-message-container {
  background: #17cfcf;
  /* max-width: 150px; */
}

#search-container {
  display: grid;
  align-items: center;
  padding: 0 10px;
  grid-area: search-container;
  border-radius: 10px 0 0 0;
  box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.75);
  z-index: 1;
}

#search-container input {
  color: #fff;
  outline: none;
  font-weight: bold;
  border-radius: 2px;
  height: 30px;
  border: 0;
  padding: 10px;
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.3);
}

#search-container input::-webkit-input-placeholder {
  color: #fff;
  font-weight: bold;
  text-align: left;
}

#search-container input:-ms-input-placeholder {
  color: #fff;
  font-weight: bold;
  text-align: left;
}

#search-container input::-ms-input-placeholder {
  color: #fff;
  font-weight: bold;
  text-align: left;
}

#search-container input::placeholder {
  color: #fff;
  font-weight: bold;
  text-align: left;
}

#conversation-list {
  grid-area: conversation-list;
  overflow-y: scroll;
}

#conversation-list > ul {
  margin: 0;
  padding: 0;
}

.conversation {
  /* display: grid;
  grid-template-columns: 40px 1fr max-content; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* grid-gap: 10px; */
  color: #fff;
  font-size: 1rem;
  border-bottom: 1px solid #339494;
  padding: 10px 10px 20px 15px;
}

.conversation.active {
  background: #000638;
}

.conversation:hover {
  cursor: pointer;
  background: #000638;
}

.conversation > svg {
  grid-row: span 2;
  height: 40px;
  width: 40px;
  border-radius: 100%;
}

.title-text {
  font-weight: bold;
  color: #eee;
  padding-left: 5px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.created-date {
  color: rgb(153, 149, 149);
  font-size: 0.5rem;
}

.conversation-message {
  grid-column: span 2;
  padding-left: 5px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

#new-message-container {
  display: grid;
  grid: 80px / 50px;
  align-content: center;
  grid-area: new-message-container;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 0 0 0 10px;
  padding: 0 15px;
}

#new-message-container a {
  display: grid;
  place-content: center center;
  background: #eee;
  border-radius: 100%;
  color: #339494;
  text-decoration: none;
  font-size: 3.6rem;
}

#chat-title,
#chat-form {
  background: #eee;
}

#chat-title {
  display: grid;
  grid: 36px / 1fr 40px;
  align-content: center;
  grid-area: chat-title;
  color: #17cfcf;
  font-weight: bold;
  border-radius: 0 10px 0 0;
  box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.75);
  padding: 0 20px;
}

#chat-title > .chat-title-username {
  font-size: 1.9rem;
}

#chat-title > svg {
  display: none;
}

#chat-message-list {
  grid-area: chat-message-list;
  display: flex;
  flex-direction: column-reverse;
  padding: 0 10px;
  overflow-y: scroll;
}

.message-row {
  display: grid;
  grid-template-columns: 70%;
  margin-bottom: 20px;
}

.message-content {
  display: grid;
}

.you-message {
  justify-content: end;
}

.you-message .message-content {
  justify-items: end;
}

.other-message {
  justify-content: start;
}

.other-message .message-content {
  grid-template-columns: 1fr 48px;
  /* grid-column-gap: 15px; */
  justify-items: start;
}

.message-row > svg {
  border-radius: 100%;
  grid-row: span 2;
}

.message-text {
  padding: 9px 14px;
  font-size: 1.6rem;
  margin-bottom: 5px;
}

.message-time {
  font-size: 1.3rem;
  color: #777;
}

.you-message .message-text {
  background: #17cfcf;
  color: #eee;
  border: 1px solid #17cfcf;
  border-radius: 14px 14px 0 14px;
}

.other-message .message-text {
  background: #eee;
  color: #111;
  border: 1px solid #ddd;
  border-radius: 14px 14px 14px 0;
}

#chat-form {
  display: grid;
  grid-template-columns: 9fr 0.5fr;
  align-content: center;
  align-items: center;
  grid-gap: 15px;
  width: 100%;
  grid-area: chat-form;
  border-radius: 0 0 10px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  padding-left: 70px;
  margin-left: 0px;
  padding-right: 22px;
}

#chat-form input {
  outline: none;
  padding: 5px;
  width: 150px;
  border: 2px solid #ddd;
  color: #330;
  border-radius: 6px;
  font-size: 1rem;
}

#chat-form > svg {
  font-size: 2rem;
}

::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 450px) {
  .message-container {
    position: -webkit-sticky;
    position: sticky;
    display: grid;
    grid:
      "search-container chat-title" 45px
      "conversation-list chat-message-list" 1fr
      "new-message-container chat-form" 45px
      / 100px 1fr;
    width: 100%;
    max-width: 300px;
  }

  #search-container,
  #conversation-list,
  #new-message-container {
    max-width: 100px;
  }

  #search-container input {
    padding: 5px;
  }

  #search-container input::-webkit-input-placeholder {
    color: #ddd;
    font-weight: bold;
    text-align: left;
  }

  #search-container input:-ms-input-placeholder {
    color: #ddd;
    font-weight: bold;
    text-align: left;
  }

  #search-container input::-ms-input-placeholder {
    color: #ddd;
    font-weight: bold;
    text-align: left;
  }

  #search-container input::placeholder {
    color: #ddd;
    font-weight: bold;
    text-align: left;
  }

  .conversation {
    padding: 10px;
    font-size: 0.85rem;
  }
  /*   
  .conversation.active,
  .conversation:hover {
    background: #000638;
  } */

  #chat-title,
  #chat-form {
    width: 200px;
  }

  #chat-title {
    display: grid;
    grid-area: chat-title;
  }

  #chat-title > .chat-title-username {
    font-size: 1rem;
  }

  #chat-title > svg {
    display: none;
  }

  #chat-form {
    padding-left: 10px;
  }
}

@media screen and (min-width: 700px) {
  .message-container {
    position: -webkit-sticky;
    position: sticky;
    display: grid;
    grid:
      "search-container chat-title" 71px
      "conversation-list chat-message-list" 1fr
      "new-message-container chat-form" 78px
      / 275px 1fr;
    min-width: 800px;
    max-width: 1000px;
    max-height: 800px;
    width: 100%;
    height: 95vh;
    background: #ddd;
    border-radius: 10px;
  }

  #search-container,
  #conversation-list,
  #new-message-container {
    background: #17cfcf;
  }

  #search-container {
    display: grid;
    align-items: center;
    padding: 0 20px;
    grid-area: search-container;
    border-radius: 10px 0 0 0;
    box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.75);
    z-index: 1;
  }

  #search-container input {
    color: #eee;
    outline: none;
    font-weight: bold;
    border-radius: 2px;
    height: 30px;
    border: 0;
    padding-left: 48px;
    padding-right: 20px;
    font-size: 1.4rem;
    background: rgba(255, 255, 255, 0.3);
  }

  #search-container input::-webkit-input-placeholder {
    color: #ddd;
    font-weight: bold;
  }

  #search-container input:-ms-input-placeholder {
    color: #ddd;
    font-weight: bold;
  }

  #search-container input::-ms-input-placeholder {
    color: #ddd;
    font-weight: bold;
  }

  #search-container input::placeholder {
    color: #ddd;
    font-weight: bold;
  }

  #conversation-list {
    grid-area: conversation-list;
    overflow-y: scroll;
  }

  #conversation-list > ul {
    margin: 0;
    padding: 0;
  }

  .conversation {
    display: grid;
    grid-template-columns: 40px 1fr -webkit-max-content;
    grid-template-columns: 40px 1fr max-content;
    grid-gap: 10px;
    color: #ddd;
    font-size: 1.3rem;
    border-bottom: 1px solid #339494;
    padding: 20px 20px 20px 15px;
  }

  /* .conversation.active,
  .conversation:hover {
    background: #000638;
  } */

  .conversation:hover {
    cursor: pointer;
  }

  .conversation > svg {
    grid-row: span 2;
    height: 40px;
    width: 40px;
    border-radius: 100%;
  }

  .title-text {
    font-weight: bold;
    color: #eee;
    padding-left: 5px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  .created-date {
    color: #ddd;
    font-size: 1rem;
  }

  .conversation-message {
    grid-column: span 2;
    padding-left: 5px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  #new-message-container {
    display: grid;
    grid: 80px / 50px;
    align-content: center;
    grid-area: new-message-container;
    border-top: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 0 0 0 10px;
    padding: 0 15px;
  }

  #new-message-container a {
    display: grid;
    place-content: center center;
    background: #eee;
    border-radius: 100%;
    color: #339494;
    text-decoration: none;
    font-size: 3.6rem;
  }

  #chat-title,
  #chat-form {
    background: #eee;
  }

  #chat-title {
    display: grid;
    grid: 36px / 1fr 36px;
    align-content: center;
    grid-area: chat-title;
    color: #17cfcf;
    font-weight: bold;
    border-radius: 0 10px 0 0;
    box-shadow: 0 1px 3px -1px rgba(0, 0, 0, 0.75);
    padding: 0 20px;
  }

  #chat-title > .chat-title-username {
    font-size: 2rem;
  }

  #chat-title > svg {
    cursor: pointer;
    font-size: 2rem;
  }

  #chat-message-list {
    grid-area: chat-message-list;
    display: flex;
    flex-direction: column-reverse;
    padding: 0 20px;
    overflow-y: scroll;
  }

  .message-row {
    display: grid;
    grid-template-columns: 70%;
    margin-bottom: 20px;
  }

  .message-content {
    display: grid;
  }

  .you-message {
    justify-content: end;
  }

  .you-message .message-content {
    justify-items: end;
  }

  .other-message {
    justify-content: start;
  }

  .other-message .message-content {
    grid-template-columns: 1fr 48px;
    grid-column-gap: 15px;
    justify-items: start;
  }

  .message-row > svg {
    border-radius: 100%;
    grid-row: span 2;
  }

  .message-text {
    padding: 9px 14px;
    font-size: 1.6rem;
    margin-bottom: 5px;
  }

  .message-time {
    font-size: 1.3rem;
    color: #777;
  }

  .you-message .message-text {
    background: #17cfcf;
    color: #eee;
    border: 1px solid #17cfcf;
    border-radius: 14px 14px 0 14px;
  }

  .other-message .message-text {
    background: #eee;
    color: #111;
    border: 1px solid #ddd;
    border-radius: 14px 14px 14px 0;
  }

  #chat-form {
    display: grid;
    grid-template-columns: 9fr 1fr;
    align-content: center;
    align-items: center;
    grid-gap: 15px;
    grid-area: chat-form;
    border-radius: 0 0 10px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.25);
    padding-left: 42px;
    padding-right: 22px;
  }

  #chat-form input {
    outline: none;
    padding: 15px;
    border: 2px solid #ddd;
    color: #330;
    width: 100%;
    border-radius: 6px;
    font-size: 1.4rem;
  }

  #chat-form > svg {
    font-size: 2rem;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}

.chatHome {
  margin: 20px;
}

.chatHome section {
  padding: 5px;
}

.chatHome > svg {
  margin-top: 50px;
  padding: 10px;
}

/* * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
} */

.chat-messages-new {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.message {
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 16px;
  width: 100%;
  text-align: left;
}

.message > p {
  display: block;
}

.message > svg {
  color: red;
}

p.chat-text-body {
  color: white;
  font-size: 1rem;
  margin-bottom: 0;
}

p.chat-text-date {
  width: 45%;
  background: ddd;
  font-size: 0.8rem;
  color: gray;
  margin-top: 0;
}

.your-message {
  display: flex;
  align-self: flex-end;
}

.your-message > svg {
  align-self: flex-end;
}

.your-message > p.chat-text-body {
  display: flex;
  align-self: flex-end;
  background: #17cfcf;
  padding: 16px;
  border-radius: 14px 14px 0 14px;
}

.your-message > p.chat-text-date {
  align-self: flex-end;
  padding: 4px 0;
}

.your-message > p {
  align-self: flex-end;
}

.other-message {
  display: flex;
  align-self: flex-start;
}

.other-message > p {
  align-self: flex-start;
}

.other-message > p.chat-text-body {
  display: flex;
  align-self: flex-start;
  background: #777;
  padding: 16px;
  border-radius: 14px 14px 14px 0;
}

.other-message > p.chat-text-date {
  color: #777;
  align-self: flex-start;
  padding: 4px 0;
}

.your-message,
.other-message {
  flex-direction: column;
}

.send-image:hover {
  cursor: pointer;
}

