@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");

* {
  box-sizing: border-box;
}

main.signUp__signIn {
  background: #f6f5f7;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  height: 120vh;
  margin: -20px 0 50px;
}

.demoUser {
  border-style: solid;
  border-radius: 50%;
  border-width: 2px;
  border-color: #dddddd;
  color: rgb(143, 143, 143);
  margin-left: -175px;
  margin-top: -30px;
  width: 125px;
  height: 125px;
}
.demoUser p {
  line-height: 15px;
}

form.signUp__signIn-form:hover {
  background: #ffffff;
}

form.sign-in-container-form:hover {
  background: #ffffff;
}

h1 {
  font-weight: bold;
  margin: 0;
}

h2 {
  text-align: center;
}

.overlay-container p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}

span {
  font-size: 12px;
}

a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}

button {
  border-radius: 20px;
  border: 1px solid #17cfcf;
  background-color: #17cfcf;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

button:hover {
  cursor: pointer;
  background-color: #000638;
}

button:active {
  transform: scale(0.95);
}

button:focus {
  outline: none;
}

button.ghost {
  background-color: transparent;
  border-color: #ffffff;
}

button.ghost:hover {
  background-color: #000638;
}

form.signUp__signIn-form,
form.sign-in-container-form {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 20px;
  height: 100%;
  text-align: center;
}

.signUp__signIn-form {
  padding-bottom: 15px;
}

input {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}

.container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: scroll;
  min-width: 350px;
  max-width: 650px;
  min-height: 690px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.form-container {
  position: absolute;
  top: 0;
  height: 50%;
  transition: all 0.6s ease-in-out;
}

.sign-in-container {
  top: 0;
  width: 100%;
  z-index: 2;
}

.container.right-panel-active .sign-in-container {
  transform: translateY(100%);
}

.sign-up-container {
  top: 10;
  width: 100%;
  opacity: 0;
  z-index: 1;
}

.container.right-panel-active .sign-up-container {
  transform: translateY(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-container {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 50%;
  overflow: scroll;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.container.right-panel-active .overlay-container {
  transform: translateY(-100%);
}

.overlay {
  background: #17cfcf;
  background: -webkit-linear-gradient(to right, #17cfcf, #25c0c0);
  background: linear-gradient(to right, #17cfcf, #25c0c0);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateY(0);
  transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateY(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-left {
  transform: translateY(-20%);
}

.container.right-panel-active .overlay-left {
  transform: translateY(0);
}

.overlay-right {
  right: 0;
  transform: translateY(0);
}

.container.right-panel-active .overlay-right {
  transform: translateY(20%);
}
/* 
@media screen and (max-width: 400px) {
  main.signUp__signIn {
    margin: 20px 0 50px;
  }
} */

@media only screen and (min-width: 768px) {
  /* main.signUp__signIn {
    margin: -20px 0px 50px;
  } */

  .demoUser {
    border-style: solid;
    border-radius: 50%;
    border-width: 2px;
    border-color: #dddddd;
    color: rgb(143, 143, 143);
    margin-left: -175px;
    margin-top: -148px;
    width: 125px;
    height: 125px;
    overflow: hidden;
  }
  .demoUser p {
    line-height: 16px;
  }

  .container {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    position: relative;
    overflow: scroll;
    width: 768px;
    max-width: 100%;
    min-height: 480px;
  }

  .form-container {
    position: absolute;
    top: 0;
    padding-bottom: none;
    padding-top: 30px;
    height: 100%;
    transition: all 0.6s ease-in-out;
  }

  .sign-in-container {
    left: 0;
    width: 50%;
    z-index: 2;
  }

  .container.right-panel-active .sign-in-container {
    transform: translateX(100%);
  }

  .sign-up-container {
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
  }

  .container.right-panel-active .sign-up-container {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    animation: show 0.6s;
  }

  @keyframes show {
    0%,
    49.99% {
      opacity: 0;
      z-index: 1;
    }

    50%,
    100% {
      opacity: 1;
      z-index: 5;
    }
  }

  .overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: scroll;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
  }

  .container.right-panel-active .overlay-container {
    transform: translateX(-100%);
  }

  .overlay {
    background: #17cfcf;
    background: -webkit-linear-gradient(to right, #17cfcf, #25c0c0);
    background: linear-gradient(to right, #17cfcf, #25c0c0);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: #ffffff;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
  }

  .container.right-panel-active .overlay {
    transform: translateX(50%);
  }

  .overlay-panel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    top: 0;
    height: 100%;
    width: 50%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
  }

  .overlay-left {
    transform: translateX(-20%);
  }

  .container.right-panel-active .overlay-left {
    transform: translateX(0);
  }

  .overlay-right {
    right: 0;
    transform: translateX(0);
  }

  .container.right-panel-active .overlay-right {
    transform: translateX(20%);
  }
}

/* 
.social-container {
  margin: 20px 0;
} */

/* .social-container a {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
} */

/* footer {
  background-color: #222;
  color: #fff;
  font-size: 14px;
  bottom: 0;
  position: fixed;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 999;
}

footer p {
  margin: 10px 0;
}

footer i {
  color: red;
}

footer a {
  color: #3c97bf;
  text-decoration: none;
} */
